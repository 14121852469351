//#region Imports
import * as Bowser from "bowser";
import * as moment from "moment-timezone";
import { Secure } from "./secure";
import { environment } from "../../../src/environments/environment";
import * as $ from "jquery";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { formatDate } from "@angular/common";

//#endregion Imports Sections
export class Utilities extends Secure {
  //#region Variables
  private user_time_zone: any;
  public WIDGET_LOGIN = "user/widget_login";
  public VERIFY_WIDGIT_TOKEN = "user/check_widget_token";
  public QUESTIONNAIRE = "questionnaire/list";
  public QUESTIONNAIRE_SAVE = "questionnaire/save";
  public MEDICAL_QUESTIONNAIRE = "questionnaire/webapp_list";
  public MEDICAL_QUESTIONNAIRE_SAVE = "questionnaire/webapp_save";
  public APPDETAILS = "user/get_app_details";
  public GOOGLE_VISION_API = "score/process_user_image";
  public PROCESS_DESIGN_MY_SMILE = "smile_design/process_image";
  public PROCESS_ORAL_HEALTH_IMAGES = "score/save_oral_image";
  public CALCULATINGSCORES = "score/calculate";
  public FETCHINGINDIVIDUALSCORES = "score/individual_score";
  public OHR_REPORTS = "score/generate_ohr_report";
  public SMILE_REPORTS = "smile_design/generate_smile_design_report";
  public WHITENING_ALIGNER = "smile_design/whitening_aligner";
  public SURVEY_QUESTIONS = "smile_design/answers_for_smile_survey_questions";
  public OHR_DMS_RATING = "score/ohr_smile_design_rating";
  public PRACTICE_SETTINGS = "practice_settings/fetch_practice_settings";
  public PATIENT_UPLOAD_IMAGE = "practice/save_patient_upload_image";
  public GETPRACTICEPAYMENTDETAILS = "practice/get_practice_payment_details";
  public RAZORPAY_PAYMENT_PROCESS = "payment/paymentProcess";
  public MAKE_STRIPE_PAYMENT = "stripe/process_stripe_payment";
  public CHECK_PAYMENT_PROCESSED = "practice/check_payment_processed";
  public INITIATE_STRIPE_PAYMENT = "stripe/initiate_stripe_payment";
  public GET_STRIPE_PAYMENT_DETAILS = "stripe/get_stripe_payment_details";
  public GET_COUNTRY_LIST = "user/get_country_state";
  public GET_STATE_LIST = "user/get_country_state";
  public DELETE_USER = "user/delete_user";
  public CHANGE_PASSWORD = "user/change_password";
  public UPDATE_WIDGET_PROFILE = "user/update_widget_user_profile";
  public DELETE_USER_ACCOUNT = "user/delete_account";
  public USER_LOGIN = "user/widget_user_login";
  public FORGOT_PASSORD = "user/widget_forgot_password";
  public REQUEST_OTP = "user/send_otp";
  public VERIFY_OTP = "user/otp_verify";
  public DELETE_IMAGE = "score/delete_oral_image";
  public GET_COUPON_CODES = "dentist/get_coupon_code";
  public SEND_EMAIL_OHR_REPORT = "user/send_email_ohr_report";
  public PAYMENT_PROCESS_CCAVENUE = "payment/payment_process_ccavenue";
  public CHECK_CCAVENUE_PAYMENT = "payment/check_ccavenue_payment";
  // public WIDGET_LATERAL_LOGIN             = "user/widget_lateral_login";
  public UPDATE_OHR_USER_ID = "user/update_ohr_user_id";
  //medical history form
  public GET_MEDICAL_HISTORY_DETAILS = "user/get_app_details";
  public UPDATE_PATIENT_INFO = "user/update_patient_info";
  public GET_PATIENT_INFO = "user/get_patient_info";
  public UPDATE_MEDICAL_HISTORY = "user/update_medical_history";
  public LAST_SAVED_ANSWER = "questionnaire/last_saved_answer";

  todaysDate = formatDate(new Date(), "yyyy/MM/dd", "en-US");

  comapareTwoTimes(visit_time) {
    var current_time = formatDate(new Date(), "HH:mm:ss", "en-US");
    var visit_time = visit_time;
    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
    if (
      parseInt(visit_time.replace(regExp, "$1$2$3")) >
      parseInt(current_time.replace(regExp, "$1$2$3"))
    ) {
      return false;
    } else {
      return true;
    }
  }

  //#endregion Variables
  getTodayDateAndTime() {
    let today = new Date();
    let todayDate = formatDate(new Date(), "yyyy-MM-dd", "en-US");
    // let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let hours =
      Number(today.getHours()) < 10 ? "0" + today.getHours() : today.getHours();
    let minutes =
      Number(today.getMinutes()) < 10
        ? "0" + today.getMinutes()
        : today.getMinutes();
    let seconds =
      Number(today.getSeconds()) < 10
        ? "0" + today.getSeconds()
        : today.getSeconds();
    let time = hours + ":" + minutes + ":" + seconds;
    let dateTime = todayDate + "T" + time;
    return dateTime;
  }

  isValidDate(visit_date) {
    let date1 = new Date(visit_date);
    let date2 = new Date(this.todaysDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days <= 0;
  }

  isTodayDate(visit_date) {
    var inputDate = new Date(visit_date);
    var todaysDate = new Date(this.todaysDate);
    // console.log(inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0))
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0))
      return true;
    else return false;
  }

  //#region getDeviceInfo
  public getDeviceInfo(ip_address, method_type, endpoint) {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details =
      userAgent.browser.name +
      "-" +
      userAgent.browser.version +
      "-" +
      userAgent.os.name +
      "-" +
      userAgent.os.versionName +
      "-" +
      userAgent.os.version;
    browser_details = browser_details.replace(/\s/g, "").toLowerCase();
    browser_details = browser_details.replace(/\./gi, "-").toLowerCase();
    this.user_time_zone = "";
    //if (endpoint == 'email_login')
    this.user_time_zone = ',"time_zone":"' + this.getUserTimeZone() + '"';

    if (method_type == "post")
      return (
        '"device" : {"source" : "web", "locale" : "en", "device_os_version": "6.0",  "device_model": "test",  "device_manufacture": "test", "device_key": "' +
        browser_details +
        '","device_os": "' +
        userAgent.os.name +
        '", "ip_address": "' +
        ip_address +
        '"' +
        this.user_time_zone +
        ', "browser": "' +
        browser_details +
        '"}'
      );
    else
      return (
        "&source=web&device_key=" +
        browser_details +
        "&ip_address=" +
        ip_address +
        '&locale=en&browser="' +
        browser_details +
        '"'
      );
  }
  //#endregion Imports Sections

  public getBrowserDetails() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details =
      userAgent.browser.name +
      "-" +
      userAgent.browser.version +
      "-" +
      userAgent.os.name +
      "-" +
      userAgent.os.versionName +
      "-" +
      userAgent.os.version;
    browser_details = browser_details.replace(/\s/g, "").toLowerCase();
    browser_details = browser_details.replace(/\./gi, "-").toLowerCase();
    return browser_details;
  }

  //#region getUserTimeZone
  public getUserTimeZone() {
    return moment.tz.guess() + "::" + moment.tz(moment.tz.guess()).format("Z");
  }
  //#endregion getUserTimeZone

  //#region setSession
  public setSession(key: any, value: any, is_json_data: boolean) {
    // console.log(" key : " + key + " value : " + value);
    if (is_json_data) localStorage.setItem(key, this.encryptJSONHandler(value));
    else localStorage.setItem(key, this.encryptHandler(value));
  }
  //#endregion setSession

  //#region clearSession
  public clearSession() {
    localStorage.clear();
  }
  //#endregion clearSession

  //#region hasSession
  public hasSession(key: any) {
    return localStorage.hasOwnProperty(key);
  }
  //#endregion hasSession

  // //#region removeAllSessions
  // public removeAllSessions() {
  //     for (var a in localStorage) {
  //         if (a.startsWith("tr_bk_"))
  //             localStorage.removeItem(a);
  //     }
  // }
  // //#endregion removeAllSessions

  //#region getSession
  public getSession(key: any, is_json_data: boolean) {
    if (is_json_data) {
      if (!this.isEmpty(localStorage.getItem(key)))
        return this.decryptJSONHandler(localStorage.getItem(key));
      return "";
    } else {
      if (!this.isEmpty(localStorage.getItem(key)))
        return this.decryptHandler(localStorage.getItem(key));
      return "";
    }
  }
  //#endregion getSession

  //#region setUserEmail
  public setUserEmail(email: any) {
    if (email.trim() != "") this.setSession("selected_email", email, false);
  }
  //#endregion setUserEmail

  //#region generteJSONFromArray
  public generteJSONFromArray(input) {
    let output: any;
    output = JSON.stringify(Object.assign({}, input));
    output = JSON.stringify(JSON.parse(output));
    return output;
  }
  //#endregion generteJSONFromArray

  //#region startLoading
  public startLoading(className: string, text: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    element.innerHTML =
      '<img src="assets/images/button_loader.gif" style="max-width:26px">';
    if (className == "loading_btn") {
      element.innerHTML =
        '<img src="assets/images/circle-loading.gif" style="max-width:26px">';
    }
    // console.log(element.innerHTML);
    element.disabled = true;
  }
  //#endregion startLoading

  //#region getHTMLElement
  public getHTMLElement(className: string) {
    let element = <HTMLElement>document.getElementsByClassName(className)[0];
    return element;
  }
  //#endregion getHTMLElement

  //#region getHTMLInputElement
  public getHTMLInputElement(className: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    return element;
  }
  //#endregion getHTMLInputElement

  //#region getHTMLInputElement
  public getHTMLInputElementArray(className: string) {
    return document.getElementsByClassName(className);
  }
  //#endregion getHTMLInputElement

  //#region getHTMLIFrameElement
  public getHTMLIFrameElement(className: string) {
    let element = <HTMLIFrameElement>(
      document.getElementsByClassName(className)[0]
    );
    return element;
  }
  //#endregion getHTMLIFrameElement

  //#region getHTMLImageElement
  public getHTMLImageElement(className: string) {
    let element = <HTMLIFrameElement>(
      document.getElementsByClassName(className)[0]
    );
    return element;
  }
  //#endregion getHTMLImageElement

  //#region stopLoading
  public stopLoading(className: string, text: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    element.innerHTML = text;
    element.disabled = false;
  }
  //#endregion stopLoading

  //#region stopLoading
  public serviceMessages(className: string, text: string, type: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    if (element) {
      if (text == "") element.innerHTML = "";
      else
        element.innerHTML =
          '<div class="alert alert-' +
          type +
          ' alert-dismissible"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' +
          text +
          "</div>";
    }
  }
  //#endregion stopLoading

  //#region isAuthenticated
  public isAuthenticated() {
    if (this.getSession("is_logged_in", false) == "true") {
      return true;
    } else {
      return false;
    }
  }
  //#endregion isAuthenticated

  //#region isFirstLogin
  public isFirstLogin() {
    if (
      JSON.parse(
        JSON.stringify(this.getSession("user_selected_products", true))
      ).length == 0 ||
      !localStorage.hasOwnProperty("is_first_login")
    ) {
      this.setSession("is_first_login", "true", false);
      return true;
    }
    return false;
  }
  //#endregion isFirstLogin

  //#region getLoggedUserFullname
  public getLoggedUserFullname() {
    let user_details = this.getSession("user_details", true);
    return user_details["first_name"] + " " + user_details["last_name"];
  }
  //#endregion getLoggedUserFullname

  //#region getLoggedUserEmail
  public getLoggedUserEmail() {
    let user_details = this.getSession("user_details", true);
    return user_details["email_address"];
  }
  //#endregion getLoggedUserEmail

  //#region getLoggedUserProfile
  public getLoggedUserProfile() {
    let user_details = this.getSession("user_details", true);

    if (user_details["profile_pic_url"] == "")
      return (
        environment.S3_BASE_URL + "app-user-profile/default-profile-pic.png"
      );
    else return user_details["profile_pic_url"];
    //return environment.S3_BASE_URL + user_details["profile_pic_url"];
  }
  //#endregion getLoggedUserProfile

  //#region isEmpty
  public isEmpty(input) {
    return input === undefined || input == null || input.length === 0;
  }
  //#endregion isEmpty

  //#region showDebugMessage
  public showDebugMessage(message) {
    $(".list-loader").remove();
    var board = document.createElement("div");
    board.className = "global-error alert alert-danger";
    board.innerHTML = "Oops something went wrong. Please retry!!!";
    document.body.appendChild(board);
    if (environment.DEBUG_ENABLED == 1) console.log(message);
  }
  //#endregion showDebugMessage

  //#region data_loading
  public data_loading() {
    return '<div class="logo_gold"><img src="assets/images/logo_gold.gif" style="max-width:150px"></div>';
  }
  //#endregion data_loading

  //#region convertStringtoJSON
  public convertStringtoJSON(input) {
    return JSON.parse(JSON.stringify(input));
  }
  //#endregion convertStringtoJSON

  //#region getPlainQueryParam
  public getQueryParam(key, route) {
    return route.snapshot.queryParamMap.get(key);
  }
  //#endregion getPlainQueryParam

  //#region getPlainQueryParam
  public getPlainQueryParam(key, route) {
    return this.decryptParamHandler(route.snapshot.queryParamMap.get(key));
  }
  //#endregion getPlainQueryParam

  //#region setEncryptQueryParam
  public setEncryptQueryParam(key, route) {
    return encodeURI(this.encryptParamHandler(key));
  }
  //#endregion setEncryptQueryParam

  //#region startIconLoading
  public startIconLoading(className: string, text: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    element.innerHTML =
      '<i class="fa fa-circle-o-notch fa-spin grey_color"></i>';
    element.disabled = true;
  }
  //#endregion startIconLoading

  //#region stopIconLoading
  public stopIconLoading(className: string, text: string) {
    let element = <HTMLInputElement>(
      document.getElementsByClassName(className)[0]
    );
    element.innerHTML = text;
    element.disabled = false;
  }
  //#endregion stopIconLoading

  //#region getDateTimeInSeconds
  public getDateTimeInSeconds() {
    let seconds = new Date().getTime().toString();
    return seconds.substr(0, 10);
  }
  //#endregion getDateTimeInSeconds

  //#region convertTimeToMilliSeconds
  public convertTimeToMilliSeconds(time) {
    let hours: number;
    let min: number;
    let sec: number;
    let timeArray: any[] = [];
    timeArray = time.split(":");
    if (timeArray.length == 2) {
      hours = 0;
      min = timeArray[0];
      sec = timeArray[1];
    } else {
      hours = timeArray[0];
      min = timeArray[1];
      sec = timeArray[2];
    }
    let seconds = +hours * 60 * 60 + +min * 60 + +sec;
    return seconds * 1000;
  }
  //#endregion convertTimeToMilliSeconds

  /**
   * uploadToS3 - Upload the files (images/pdf) to S3
   * @param   {file}  file
   * @param   {file_name}  file name
   * @param   {folder_name}  folder name
   * @since   1.0
   * @returns {boolean}
   */
  public uploadToS3(file, file_name, folder_name, type) {
    // console.log(file_name)
    let promise = new Promise(function (resolve, reject) {
      const bucket = new S3({
        accessKeyId: environment.AWS_ACCESS_KEY,
        secretAccessKey: environment.AWS_SECRET_KEY,
        region: environment.AWS_REGION,
      });
      const params = {
        Bucket: environment.S3_BUCKET_NAME,
        Key: folder_name + file_name,
        Body: file,
        ACL: "public-read",
        ContentType: type,
        // ContentType: file.type
      };

      bucket.upload(params, function (err, data) {
        if (err) {
          //    console.log('There was an error uploading your file: ' + err);
          reject(err);
        }
        // console.log('Successfully uploaded file.' + data);
        resolve(data);
      });
    });
    return promise;
  }

  /**
   * deleteFromS3 - Delete the files (images/pdf) from S3
   * @param   {file_path}  file path of the s3
   * @since   1.0
   * @returns {boolean}
   */
  public static deleteFromS3(file_path) {
    const bucket = new S3({
      accessKeyId: environment.AWS_ACCESS_KEY,
      secretAccessKey: environment.AWS_SECRET_KEY,
      region: environment.AWS_REGION,
    });
    const params = {
      Bucket: environment.S3_BUCKET_NAME,
      Key: file_path,
    };
    bucket.deleteObject(params, function (err, data) {
      if (err) {
        //    console.log('There was an error uploading your file: ' + err);
        return false;
      }
      // console.log('Successfully deleted file.' + data);
      return true;
    });
  }
  public convertDataUrlToBlob(dataUrl): Blob {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
  getImageWidth(id) {
    let output = "";
    if (id == 0) output = "max_width1";
    else if (id == 1) output = "max_width2";
    else if (id == 2) output = "max_width3";
    return output;
  }
  getColor(count) {
    let score = Number(count);
    let classname = "";
    if (score <= 4) classname = "red_color";
    else if (score >= 5 && score <= 7) classname = "orange_color";
    else classname = "green_color";
    return classname;
  }
  getText(count) {
    let score = Number(count);
    let text = "";
    if (score <= 4) text = "Red";
    else if (score >= 5 && score <= 7) text = "Amber";
    else text = "Green";
    return text;
  }
  getImage(count) {
    let score = Number(count);
    let image = "";
    if (score <= 4) image = "assets/images/red.png";
    else if (score >= 5 && score <= 7) image = "assets/images/orange.png";
    else image = "assets/images/green.png";
    return image;
  }
  isLoadingFromIframe() {
    if (window.location !== window.parent.location) return true;
    else return false;
  }
  sendAnalyticsData(
    gtag = '',
    fbq = '',
    event_name,
    event_category,
    is_admin_event: boolean
  ) {
    let ga_code = is_admin_event
      ? environment.GA_TRACKING_ID
      : this.getSession("gtm_id", false);
    if (!this.isEmpty(ga_code)) {
      if (this.isLoadingFromIframe()) {
        let event_message: any = {
          type: "click",
          event_name: event_category,
          send_to: ga_code,
          event_category: event_category,
          event_label: this.getBrowserDetails(),
        };
        window.parent.postMessage(
          { action: "iframe-message", params: event_message },
          "*"
        );
      } else {
        // gtag("event", event_category, {
        //   send_to: ga_code,
        //   event_category: event_category,
        //   event_label: this.getBrowserDetails(),
        // });
      }
    }
    // let fb_code = is_admin_event
    //   ? environment.FBPIXEL_ID
    //   : this.getSession("fb_pixel_id", false);
    // if (!this.isEmpty(fb_code)) {
    //   fbq("trackSingleCustom", fb_code, "select_content", {
    //     event_category: "true",
    //   });
    // }
  }

  sendPageViewAnalyticsData(gtag = '', fbq = '', page_path, page_title) {
    // sendPageViewAnalyticsData(gtag, page_path, page_title) {
    if (this.isLoadingFromIframe()) {
      if (!page_title.toLowerCase().startsWith("smilo.ai")) {
        let event_message: any = {
          type: "view",
          send_to: environment.GA_TRACKING_ID,
          page_path: page_path,
          page_title: page_title,
        };
        window.parent.postMessage(
          { action: "iframe-message", params: event_message },
          "*"
        );
      }
    } else {
      // // console.log("standard")
      // if (!page_title.toLowerCase().startsWith("smilo.ai")) {
      //   gtag("event", "page_view", {
      //     page_path: page_path,
      //     page_title: page_title,
      //   });
      // }
    }
  }

  getSmileyImage(count) {
    let score = Number(count);
    let image = "";
    if (score <= 4) image = "assets/images/poor.png";
    // image = 'assets/images/Needle-Poor.png';
    else if (score >= 5 && score <= 7) image = "assets/images/amber.png";
    // image = 'assets/images/Needle-Average.png';
    else image = "assets/images/excellent.png";
    // image = 'assets/images/Needle-Excellent.png'
    return image;
  }

  getSmileyText(count) {
    let score = Number(count);
    let smileyText = "";
    if (score <= 4) smileyText = "Severe";
    else if (score >= 5 && score <= 7) smileyText = "Moderate";
    else smileyText = "Good";
    return smileyText;
  }
}
