export const environment = {
  production: true,
  DEBUG_ENABLED: 1,
  IPDATA_KEY: "6d856a440c524419041b70c096e8141fe86a4e0f65d0056f4f436626",
  IPDATA_API: "https://api.ipdata.co",
  // GA_TRACKING_ID: "G-NCQHVNT6YS",//old id backup

  /**@description India Region - Smilo: Haleon 2.0 QA */
  // ENV: "live",
  // REDIRECT_URL: "/",
  // BASE_PATH: "/",
  // S3_BASE_URL: "https://smilo-health-live-qa.s3-ap-south-1.amazonaws.com/",
  // AES_ENC_SECRET_KEY: "dDkWyTcpancwALsZF7PB3b5KCKvmirxn",
  // AES_ENC_IV: "f09c01zyc2x5HdhG",
  // API_BASE_URL: "https://secure-api.qa.smilo.health/api/app/v2/",
  // AUTHORIZATION_KEY:
  //   "Rwvf4MESc6fYp_wN7rLBXehH9yuHmsMWfuUdftCif96L6WG3MLkGS7PjD7_kvERCcXhxpe",
  // AWS_ACCESS_KEY: "AKIAWCDD25BTA3RL5VMB",
  // AWS_SECRET_KEY: "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",
  // S3_BUCKET_NAME: "smilo-health-live-qa",
  // LANDING_PAGE: "https://www.dev.smilo.ai/new-design-landing-page/",
  // LANDING_PAGE_SINGLE_IMAGE:
  //   "https://www.dev.smilo.ai/landing-page-single-image-widget/",
  // LANDING_PAGE_DABUR: "https://www.smilo.ai/dabur-dental-health-check/",
  // HOME_PAGE: "https://www.smilo.ai",
  // CONSENT_FORM: "https://www.dev.smilo.ai/in/consent-form/",
  // DABUR_CONSENT_FORM: "https://www.smilo.ai/dabur-consent-form/",
  // PRIVACY: "https://www.dev.smilo.ai/in/privacy-policy-new/",
  // DABUR_PRIVACY: "https://www.smilo.ai/dabur-privacy-policy/",
  // TERMS: "https://www.dev.smilo.ai/in/terms-and-conditions-new/",
  // DABUR_TERMS: "https://www.smilo.ai/dabur-terms-and-conditions/",
  // LICENCE_AGREEMENT: "https://www.dev.smilo.ai/in/licence-agreement-new/",
  // IN_PRIVACY: "https://www.dev.smilo.ai/in/privacy-policy-new/",
  // IN_TERMS: "https://www.dev.smilo.ai/in/terms-and-conditions-new/",
  // IN_CONSENT_FORM: "https://www.dev.smilo.ai/in/consent-form/",
  // SMILO_TOKEN: "P2Bb5EWOQS0WFzrYLNIn6fuiGMWFgdOULwM6VD6yedwbpxjmLB",
  // FBPIXEL_ID: "1281636479006304",
  // GTM_ID: "G-Y9KMZJD340",
  // GA_TRACKING_ID: "G-RFRC665QZP",
  // TITLE_PREFIX: "Smilo.health - ",
  // AWS_REGION: "ap-south-1",
  // CLIENT_WHATSAPP_CONTACT: "https://wa.me/919741296904",
  // RAZOR_PAY_KEY: "rzp_test_Hx6Vk9dkTogcCR",
  // GET_IN_TOUCH: "https://www.dev.smilo.ai/in/contact/",
  // DISCLAIMER_PAGE: "https://www.dev.smilo.ai/in/disclaimer/",
  // DABUR_DISCLAIMER_PAGE: "https://www.smilo.ai/dabur-disclaimer/",
  // GTM_ID_INDIA: "GTM-PS7N4D6",
  // DOMAIN_URL: "india.qa.smilo.health",
  // CCAVENUE_ACCESS_CODE: "AVIT05KJ27BH62TIHB",
  // CCAVENUE_REDIRECTION_URL:
  //   "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",

  // /**@description India Region - Smilo: Haleon 2.0 UAT */
  // ENV: "live",
  // REDIRECT_URL: "/",
  // BASE_PATH: "/",
  // S3_BASE_URL: "https://haleon-2-live.s3-ap-south-1.amazonaws.com/",
  // AES_ENC_SECRET_KEY: "dDkWyTcpancwALsZF7PB3b5KCKvmirxn",
  // AES_ENC_IV: "f09c01zyc2x5HdhG",
  // API_BASE_URL: "https://secure-api.smilo.health/api/app/v2/",
  // AUTHORIZATION_KEY:
  //   "Rwvf4MESc6fYp_wN7rLBXehH9yuHmsMWfuUdftCif96L6WG3MLkGS7PjD7_kvERCcXhxpe",
  // AWS_ACCESS_KEY: "AKIAWCDD25BTA3RL5VMB",
  // AWS_SECRET_KEY: "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",
  // S3_BUCKET_NAME: "haleon-2-live",
  // LANDING_PAGE: "https://www.smilo.ai/in/dentalhealthcheck",
  // LANDING_PAGE_SINGLE_IMAGE: "https://www.smilo.ai/in/smilecheck/",
  // LANDING_PAGE_DABUR: "https://www.smilo.ai/dabur-dental-health-check/",
  // HOME_PAGE: "https://www.smilo.ai",
  // CONSENT_FORM: "https://www.smilo.ai/in/consent-form/",
  // DABUR_CONSENT_FORM: "https://www.smilo.ai/dabur-consent-form/",
  // PRIVACY: "https://www.smilo.ai/in/privacy-policy/",
  // DABUR_PRIVACY: "https://www.smilo.ai/dabur-privacy-policy/",
  // TERMS: "https://www.smilo.ai/in/terms-and-conditions/",
  // DABUR_TERMS: "https://www.smilo.ai/dabur-terms-and-conditions/",
  // LICENCE_AGREEMENT: "https://www.dev.smilo.ai/in/licence-agreement-new/",
  // IN_PRIVACY: "https://www.smilo.ai/in/privacy-policy/",
  // IN_TERMS: "https://www.smilo.ai/in/terms-and-conditions/",https://secure-api.smilo.health/api/app/v2/
  // IN_CONSENT_FORM: "https://www.smilo.ai/in/consent-form/",
  // SMILO_TOKEN: "lDCk0HLzjkL9hAUxmOGvPPkL3PjdLRY7qp01DH4TZx1KQGynyy",
  // FBPIXEL_ID: "1281636479006304",
  // GTM_ID: "G-Y9KMZJD340",
  // GA_TRACKING_ID: "G-RFRC665QZP",
  // TITLE_PREFIX: "Smilo.health - ",
  // AWS_REGION: "ap-south-1",
  // CLIENT_WHATSAPP_CONTACT: "https://wa.me/919741296904",
  // RAZOR_PAY_KEY: "rzp_test_Hx6Vk9dkTogcCR",
  // GET_IN_TOUCH: "https://www.smilo.ai/in/contact/",
  // DISCLAIMER_PAGE: "https://www.smilo.ai/in/disclaimer/",
  // DABUR_DISCLAIMER_PAGE: "https://www.smilo.ai/dabur-disclaimer/",
  // GTM_ID_INDIA: "GTM-PS7N4D6",
  // DOMAIN_URL: "india.smilo.health",
  // CCAVENUE_ACCESS_CODE: "AVSY22KJ64BI72YSIB",
  // CCAVENUE_REDIRECTION_URL:
  //   "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",

  /**@description - Smilo: Haleon 2.0 DEV  */
  // ENV: "dev",
  // REDIRECT_URL: "/",
  // BASE_PATH: "/",
  // S3_BASE_URL: "https://haleon-2-live.s3-ap-south-1.amazonaws.com/",
  // AES_ENC_SECRET_KEY: "dDkWyTcpancwALsZF7PB3b5KCKvmirxn",
  // AES_ENC_IV: "f09c01zyc2x5HdhG",
  // // API_BASE_URL: "https://secure-api.smilo.health/api/app/v2/",
  // API_BASE_URL: "https://smilowidgetapi.tech-active.com/api/app/v2/",
  // AUTHORIZATION_KEY:
  //   "Rwvf4MESc6fYp_wN7rLBXehH9yuHmsMWfuUdftCif96L6WG3MLkGS7PjD7_kvERCcXhxpe",
  // AWS_ACCESS_KEY: "AKIAWCDD25BTA3RL5VMB",
  // AWS_SECRET_KEY: "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",
  // S3_BUCKET_NAME: "haleon-2-live",
  // // LANDING_PAGE: "https://www.smilo.ai/in/dentalhealthcheck",
  // LANDING_PAGE: "https://smilowidget.tech-active.com/in/dentalhealthcheck",

  // LANDING_PAGE_SINGLE_IMAGE: "https://www.smilo.ai/in/smilecheck/",
  // LANDING_PAGE_DABUR: "https://www.smilo.ai/dabur-dental-health-check/",
  // HOME_PAGE: "https://www.smilo.ai",
  // CONSENT_FORM: "https://www.smilo.ai/in/consent-form/",
  // DABUR_CONSENT_FORM: "https://www.smilo.ai/dabur-consent-form/",
  // PRIVACY: "https://www.smilo.ai/in/privacy-policy/",
  // DABUR_PRIVACY: "https://www.smilo.ai/dabur-privacy-policy/",
  // TERMS: "https://www.smilo.ai/in/terms-and-conditions/",
  // DABUR_TERMS: "https://www.smilo.ai/dabur-terms-and-conditions/",
  // LICENCE_AGREEMENT: "https://www.dev.smilo.ai/in/licence-agreement-new/",
  // IN_PRIVACY: "https://www.smilo.ai/in/privacy-policy/",
  // IN_TERMS: "https://www.smilo.ai/in/terms-and-conditions/",
  // IN_CONSENT_FORM: "https://www.smilo.ai/in/consent-form/",
  // // SMILO_TOKEN: "lDCk0HLzjkL9hAUxmOGvPPkL3PjdLRY7qp01DH4TZx1KQGynyy",
  // // SMILO_TOKEN: "pxuOwQfvUXMRqp6kq1XZYxhm1CIbg1cUi8nmSyng970SGoUl2l",
  // SMILO_TOKEN: "9dkd6uZ8B8KD46a3HTiBtQbs87nYpOKDjftWHdsHuv1saU13IE",

  // FBPIXEL_ID: "1281636479006304_comment",
  // GTM_ID: "G-Y9KMZJD340_comment",
  // GA_TRACKING_ID: "G-RFRC665QZP_comment",
  // TITLE_PREFIX: "Smilo.health - ",
  // AWS_REGION: "ap-south-1",
  // CLIENT_WHATSAPP_CONTACT: "https://wa.me/919741296904",
  // RAZOR_PAY_KEY: "rzp_test_Hx6Vk9dkTogcCR",
  // GET_IN_TOUCH: "https://www.smilo.ai/in/contact/",
  // DISCLAIMER_PAGE: "https://www.smilo.ai/in/disclaimer/",
  // DABUR_DISCLAIMER_PAGE: "https://www.smilo.ai/dabur-disclaimer/",
  // GTM_ID_INDIA: "GTM-PS7N4D6_comment",
  // DOMAIN_URL: "smilowidget.tech-active.com",
  // CCAVENUE_ACCESS_CODE: "AVSY22KJ64BI72YSIB",
  // CCAVENUE_REDIRECTION_URL:
  //   "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction",

    /**@description - Smilo: Tech active  DEV  */
  ENV: "dev",
  REDIRECT_URL: "/",
  BASE_PATH: "/",
  S3_BASE_URL: "https://smilo-health-live-qa.s3.ap-south-1.amazonaws.com/",
  AES_ENC_SECRET_KEY: "dDkWyTcpancwALsZF7PB3b5KCKvmirxn",
  AES_ENC_IV: "f09c01zyc2x5HdhG",
  // API_BASE_URL: "https://secure-api.qa.smilo.health/api/app/v2/",
    // API_BASE_URL: "https://smilowidgetapi.tech-active.com/api/app/v2/",
  // API_BASE_URL:"https://api-d1.smilo.health/api/app/v2/",
  API_BASE_URL: "https://v2sec-apis.tech-active.com/api/app/v2/",
  AUTHORIZATION_KEY:
    "Rwvf4MESc6fYp_wN7rLBXehH9yuHmsMWfuUdftCif96L6WG3MLkGS7PjD7_kvERCcXhxpe",
  AWS_ACCESS_KEY: "AKIAWCDD25BTA3RL5VMB",
  AWS_SECRET_KEY: "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",
  S3_BUCKET_NAME: "smilo-health-live-qa",
  LANDING_PAGE: "https://www.dev.smilo.ai/new-design-landing-page/",
  LANDING_PAGE_SINGLE_IMAGE:
    "https://www.dev.smilo.ai/landing-page-single-image-widget/",
  LANDING_PAGE_DABUR: "https://www.smilo.ai/dabur-dental-health-check/",
  HOME_PAGE: "https://www.smilo.ai",
  CONSENT_FORM: "https://www.dev.smilo.ai/in/consent-form/",
  DABUR_CONSENT_FORM: "https://www.smilo.ai/dabur-consent-form/",
  PRIVACY: "https://www.dev.smilo.ai/in/privacy-policy-new/",
  DABUR_PRIVACY: "https://www.smilo.ai/dabur-privacy-policy/",
  TERMS: "https://www.dev.smilo.ai/in/terms-and-conditions-new/",
  DABUR_TERMS: "https://www.smilo.ai/dabur-terms-and-conditions/",
  LICENCE_AGREEMENT: "https://www.dev.smilo.ai/in/licence-agreement-new/",
  IN_PRIVACY: "https://www.dev.smilo.ai/in/privacy-policy-new/",
  IN_TERMS: "https://www.dev.smilo.ai/in/terms-and-conditions-new/",
  IN_CONSENT_FORM: "https://www.dev.smilo.ai/in/consent-form/",
  // SMILO_TOKEN: "P2Bb5EWOQS0WFzrYLNIn6fuiGMWFgdOULwM6VD6yedwbpxjmLB",
  // SMILO_TOKEN: "9dkd6uZ8B8KD46a3HTiBtQbs87nYpOKDjftWHdsHuv1saU13IE",
  // SMILO_TOKEN: "BWzZ2nPAnR881r0fkhsOHsjDiZgsEdFJsR7oX3PxyW5CUVZETL", //Bupa_token
    // SMILO_TOKEN: "EFGqHBEwYQkImNrUMbU8tCjZXkBG0DqZ4qawkPKMynSngQKWoT",
  SMILO_TOKEN: 'Shz8fZ0Itbdmrvx2mY6Ye9H09g2OkHXBd9nVIl2AX8lljTodcl',
  FBPIXEL_ID: "1281636479006304_comment",
  GTM_ID: "G-Y9KMZJD340_comment",
  GA_TRACKING_ID: "G-RFRC665QZP_comment",
  TITLE_PREFIX: "Smilo.health - ",
  AWS_REGION: "ap-south-1",
  CLIENT_WHATSAPP_CONTACT: "https://wa.me/919741296904",
  RAZOR_PAY_KEY: "rzp_test_Hx6Vk9dkTogcCR",
  GET_IN_TOUCH: "https://www.dev.smilo.ai/in/contact/",
  // DISCLAIMER_PAGE: "https://www.dev.smilo.ai/in/disclaimer/",
  DISCLAIMER_PAGE: "https://www.smilo.ai/in/disclaimer/",
  DABUR_DISCLAIMER_PAGE: "https://www.smilo.ai/dabur-disclaimer/",
  GTM_ID_INDIA: "GTM-PS7N4D6_comment",
  DOMAIN_URL: "india.qa.smilo.health",
  CCAVENUE_ACCESS_CODE: "AVIT05KJ27BH62TIHB",
  CCAVENUE_REDIRECTION_URL:
    "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",

};
